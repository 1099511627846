import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { supportedLocales } from "@/constants/SupportedLocales";
import { printSupportInfo } from "@/helpers/supportInfo";
// eslint-disable-next-line no-restricted-imports
import { i18n, setI18nLanguage } from "@/i18n";
import { getApplicationSettingsService } from "@/services/applicationSettings.service";
import {
  type CurrentUserDto,
  type GetApplicationSettingsResponse,
  Role,
} from "@/types/_generated/api";

type ApplicationSettings = Omit<GetApplicationSettingsResponse, "responseStatus"> & {
  locale: string;
};

const APP_DEFAULT_SETTINGS: ApplicationSettings = {
  locale: "en",
  bingMapKey: undefined,
  defaultModuleRecommendationTypes: [],
  documentFileExtensions: [],
  documentMaxSizeInBytes: 0,
  excelFileExtensions: [],
  imageFileExtensions: [],
  imageMaxSizeInBytes: 0,
  recommendationResponseFileExtensions: [],
  version: "",
};

export const useAppStore = defineStore("app", () => {
  const appSettings = ref<ApplicationSettings>({ ...APP_DEFAULT_SETTINGS });
  const user = ref<CurrentUserDto | undefined>(undefined);

  const isAdmin = computed(() => {
    const adminRoles = [Role.SystemOwner, Role.SA, Role.BA];
    return user.value ? adminRoles.includes(user.value.role) : false;
  });

  const currentLanguage = computed(() => {
    return supportedLocales.find((item) => item.value === appSettings.value.locale)?.title;
  });

  const updateLocale = (locale: string) => {
    appSettings.value.locale = locale;
    setI18nLanguage(i18n, locale);
  };

  const loadSettings = async () => {
    try {
      const data = await getApplicationSettingsService();
      appSettings.value = { ...appSettings.value, ...data };
    } catch {
      // Ignore. Service displays error message
    }
  };

  const updateUser = async (currentUser: CurrentUserDto) => {
    try {
      if (!user.value && currentUser) {
        // Load settings
        const data = await getApplicationSettingsService();
        appSettings.value = { ...appSettings.value, ...data };
      }
      user.value = currentUser;
      updateLocale(currentUser.languageCode);
      printSupportInfo(appSettings.value.version, appSettings.value.locale, currentUser);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Failed to set user:", error);
      $reset();
    }
  };

  const $reset = () => {
    appSettings.value = { ...APP_DEFAULT_SETTINGS };
    user.value = undefined;
  };

  return {
    appSettings,
    user,
    currentLanguage,
    isAdmin,
    updateLocale,
    loadSettings,
    updateUser,
    $reset,
  };
});
