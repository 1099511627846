<script setup lang="ts">
import { onMounted } from "vue";
import { useAuthentication, remoteAuthBroadcastService } from "@/composables/useAuthentication";
import { useSessionTimeout } from "@/composables/useSessionTimeout";
import { useUnsavedChanges } from "@/composables/useUnsavedChanges";
import { useAuthStore } from "@/stores/auth.store";
import { useNotificationsStore } from "@/stores/notifications.store";
import { addCookieBanner } from "@/utils/cookieBanner";
import AppDialog from "@/components/app/AppDialog.vue";
import AppImpersonateWarning from "@/components/app/AppImpersonateWarning.vue";
import AppSessionModal from "@/components/app/AppSessionModal.vue";
import AppUnsavedChangesModal from "@/components/app/AppUnsavedChangesModal.vue";
import AppToast from "@/components/app/Toast/AppToast.vue";

const authStore = useAuthStore();
const notificationsStore = useNotificationsStore();
const { isFullyAuthenticated } = useAuthentication();
const {
  unsavedChangesDialog: { showDialog, ok, cancel },
} = useUnsavedChanges();
const { showSessionModal, formattedTime, handleSignOut, closeModal } = useSessionTimeout();

remoteAuthBroadcastService.subscribe(() => window.document.location.reload(), ["login"]);
remoteAuthBroadcastService.subscribe(() => {
  authStore.resetGlobalState();
  window.document.location.reload();
}, ["logout"]);

onMounted(() => addCookieBanner());
</script>

<template>
  <AppImpersonateWarning v-if="authStore.isImpersonated" />

  <RouterView />

  <AppSessionModal
    v-if="showSessionModal && isFullyAuthenticated"
    :timer="formattedTime"
    @close="closeModal"
    @sign-out="handleSignOut"
  />

  <AppToast :toasts="notificationsStore.toasts" @remove="notificationsStore.removeToast" />

  <AppDialog />

  <AppUnsavedChangesModal v-if="showDialog" @cancel="cancel" @confirm="ok" />
</template>
