import { storeToRefs } from "pinia";
import { computed } from "vue";
import { isSuperior } from "@/helpers/isSuperior";
import { useAppStore } from "@/stores/app.store";
import { type BaseModulePermissionDto, Role } from "@/types/_generated/api";

export type ExtRule = (typeof EXT_RULES)[number];

export type Rule = Extract<
  keyof BaseModulePermissionDto,
  ExtRule | "editQuestionnaire" | "uploadFiles" | "accountEngineer"
>;

const EXT_RULES = ["createSite", "createSurvey", "editSurvey", "uploadStdRec"] as const;

export const useAuthorization = () => {
  const { user } = storeToRefs(useAppStore());

  const isAdminRole = computed(() => hasRole(Role.SystemOwner, Role.SA, Role.BA));

  const isSystemOwner = computed(() => user.value?.role === Role.SystemOwner);

  const hasModulePermissions = computed(() => {
    return (
      isSuperiorOrEqualTo(Role.SA) || (user.value && Boolean(user.value.modulePermissions?.length))
    );
  });

  const isExtWithAdminAccess = (moduleId: number) => hasModulePermission(moduleId, "editSurvey");

  const isSelf = (userId: number) => user.value?.id === userId || false;

  const hasRole = (...allowedRoles: Role[]) => user.value && allowedRoles.includes(user.value.role);

  const hasAllowRule = (rule: "allowReportMail" | "allowRiskManagerComment" | "allowUserWeb") =>
    user.value?.[rule] ?? false;

  const isSuperiorTo = (role: Role) => {
    const userRole = user.value?.role;
    return userRole ? isSuperior(userRole, role) : false;
  };

  const isSuperiorOrEqualTo = (role: Role) => {
    const userRole = user.value?.role;
    if (!userRole) return false;
    return userRole === role || isSuperior(userRole, role);
  };

  const findModulePermission = (moduleId: number) =>
    user.value?.modulePermissions?.find((mp) => mp.moduleId === moduleId);

  const hasClientPermission = (clientId: number, rule?: Rule) =>
    hasModuleAccessByRole() ||
    user.value?.modulePermissions?.some(
      (mp) => mp.clientId === clientId && hasModuleAccessByPermission(mp, rule),
    );

  const hasAnyModulePermission = (rule?: Rule) =>
    user.value?.modulePermissions?.some((mp) => hasModuleAccessByPermission(mp, rule));

  const hasModulePermission = (moduleId: number | null | undefined, rule?: Rule) => {
    if (!moduleId) {
      return false;
    }

    if (hasModuleAccessByRole()) {
      return true;
    }

    const modulePermission = findModulePermission(moduleId);

    if (!modulePermission) {
      return false;
    }

    return hasModuleAccessByPermission(modulePermission, rule);
  };

  const hasModuleAccessByRole = () => {
    if (!user.value?.role) return false;
    return isSuperiorOrEqualTo(Role.SA);
  };

  const hasModuleAccessByPermission = (modulePermission: BaseModulePermissionDto, rule?: Rule) => {
    // User has no module permissions
    if (!user.value?.modulePermissions?.length) return false;

    if (!modulePermission) return false;

    // BA has full module access
    if (user.value?.role === Role.BA) return true;

    // If no specific rule is required, having module permission is enough
    if (!rule) return true;

    const hasRule = Boolean(modulePermission[rule]);
    const isExtRule = EXT_RULES.includes(rule as ExtRule);

    // Role-specific permission checks
    switch (user.value?.role) {
      case Role.EXT:
        return isExtRule && hasRule;
      case Role.INT:
        return hasRule;
      // All other roles have no access
      default:
        return false;
    }
  };

  return {
    isAdminRole,
    isSystemOwner,
    hasModulePermissions,
    isSelf,
    isSuperiorTo,
    isSuperiorOrEqualTo,
    isExtWithAdminAccess,
    hasRole,
    hasAllowRule,
    hasClientPermission,
    hasModulePermission,
    hasAnyModulePermission,
  };
};
