import { ref } from "vue";
import type { CurrentUserDto } from "@/types/_generated/api";
import { useAuthentication } from "@/composables/useAuthentication";
import { endImpersonationService, impersonateService } from "@/services/impersonate.service";
import { useAppStore } from "@/stores/app.store";
import { useAuthStore } from "@/stores/auth.store";

export const useImpersonation = () => {
  const appStore = useAppStore();
  const authStore = useAuthStore();
  const { redirectOnLogin } = useAuthentication();

  const isLoadingImpersonation = ref(false);

  const resetUserPermissions = (user: CurrentUserDto) => {
    appStore.updateUser({
      ...user,
      modulePermissions: undefined,
      sitePermissions: undefined,
    });
  };

  const updateUserAndLocale = (user: CurrentUserDto, isImpersonated: boolean) => {
    appStore.updateUser(user);
    appStore.updateLocale(user.languageCode);
    authStore.isImpersonated = isImpersonated;
  };

  const impersonate = async (userId: number) => {
    isLoadingImpersonation.value = true;

    try {
      const { user, isImpersonated } = await impersonateService(userId);

      updateUserAndLocale(user, isImpersonated);
      redirectOnLogin();
    } finally {
      isLoadingImpersonation.value = false;
    }
  };

  const endImpersonation = async () => {
    isLoadingImpersonation.value = true;

    try {
      const { user, isImpersonated } = await endImpersonationService();

      updateUserAndLocale(user, isImpersonated);
      resetUserPermissions(user);

      window.location.reload(); // Refresh the page
    } finally {
      isLoadingImpersonation.value = false;
    }
  };

  return {
    impersonate,
    endImpersonation,
    isLoadingImpersonation,
  };
};
