import axios from "axios";
import { updateSessionActivity } from "@/composables/useSessionTimeout";
import { useAuthStore } from "@/stores/auth.store";
import { antiForgeryInterceptor } from "@/utils/antiForgery";

const http = axios.create({
  baseURL: "/v1",
  headers: {
    "Content-Type": "application/json",
    // Cache-Control is for managing caching from HTTP 1.1+
    // When HTTP 1.0 was still common, a combination of Expires: -1, Pragma: no-cache was commonly used
    "Cache-Control": "no-cache",
  },
});

http.interceptors.request.use(antiForgeryInterceptor);

http.interceptors.response.use((config) => {
  const authStore = useAuthStore();

  if (authStore.isAuthenticated) {
    updateSessionActivity(); // Update user activity to reset session timeout timer
  }

  return config;
});

http.defaults.withCredentials = true;

export { http };
