import type { BaseResponse, ResponseStatus, ResponseError } from "@/types/_generated/api";

export class DefaultBaseResponse implements BaseResponse {
  responseStatus: ResponseStatus;

  constructor() {
    this.responseStatus = new DefaultResponseStatus();
  }
}

class DefaultResponseStatus implements ResponseStatus {
  errorCode: string;
  message: string;
  stackTrace: string;
  errors: ResponseError[];
  meta: { [index: string]: string };

  constructor() {
    this.errorCode = "";
    this.message = "";
    this.stackTrace = "";
    this.errors = [];
    this.meta = {};
  }
}
