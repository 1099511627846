import { defineAsyncComponent, type Component } from "vue";
import type { icons } from "lucide-vue-next";

const iconCache = new Map<string, Component>();

/**
 * Creates an async component loader with error handling and retry logic
 * @param loader - Function that imports the icon component
 * @param iconName - Name of the icon for error reporting
 * @param type - Source of the icon (Lucide or Custom)
 */
export const createAsyncIconLoader = (
  loader: () => Promise<{ default: Component }>,
  iconName: string,
  type: "Lucide" | "Custom",
) => {
  // Check if icon is already in cache
  if (iconCache.has(iconName)) {
    return iconCache.get(iconName);
  }

  // If not in cache, create async component and cache it
  const asyncComponent = defineAsyncComponent({
    loader: async () => {
      const component = await loader();
      iconCache.set(iconName, component.default);
      return component;
    },
    onError(error, retry, _fail, attempts) {
      if (attempts <= 3) {
        retry();
      } else {
        // eslint-disable-next-line no-console
        console.error(`Failed to load ${type} icon: ${iconName}`, error);
      }
    },
  });

  return asyncComponent;
};

/**
 * Size mapping for Lucide icons (in pixels)
 */
export const lucideSizeMap = {
  small: 12,
  medium: 16,
  large: 48,
} as const;

/**
 * Map of supported Lucide icons with their dynamic imports
 * Add new icons here as needed
 */
export const lucideIconsMap = {
  // Vite's static analysis requires hardcoded import paths
  Check: () => import("lucide-vue-next/dist/esm/icons/check.js"),
  Info: () => import("lucide-vue-next/dist/esm/icons/info.js"),
  TriangleAlert: () => import("lucide-vue-next/dist/esm/icons/triangle-alert.js"),
  Menu: () => import("lucide-vue-next/dist/esm/icons/menu.js"),
  ChevronLeft: () => import("lucide-vue-next/dist/esm/icons/chevron-left.js"),
  ChevronRight: () => import("lucide-vue-next/dist/esm/icons/chevron-right.js"),
  ChevronUp: () => import("lucide-vue-next/dist/esm/icons/chevron-up.js"),
  ChevronDown: () => import("lucide-vue-next/dist/esm/icons/chevron-down.js"),
  Zap: () => import("lucide-vue-next/dist/esm/icons/zap.js"),
  Slash: () => import("lucide-vue-next/dist/esm/icons/slash.js"),
  Ellipsis: () => import("lucide-vue-next/dist/esm/icons/ellipsis.js"),
  CircleCheck: () => import("lucide-vue-next/dist/esm/icons/circle-check.js"),
  CircleX: () => import("lucide-vue-next/dist/esm/icons/circle-x.js"),
  Save: () => import("lucide-vue-next/dist/esm/icons/save.js"),
  Trash: () => import("lucide-vue-next/dist/esm/icons/trash.js"),
  RotateCwSquare: () => import("lucide-vue-next/dist/esm/icons/rotate-cw-square.js"),
  ArrowUp: () => import("lucide-vue-next/dist/esm/icons/arrow-up.js"),
  ArrowDown: () => import("lucide-vue-next/dist/esm/icons/arrow-down.js"),
  ArrowLeft: () => import("lucide-vue-next/dist/esm/icons/arrow-left.js"),
  ArrowRight: () => import("lucide-vue-next/dist/esm/icons/arrow-right.js"),
  CircleOff: () => import("lucide-vue-next/dist/esm/icons/circle-off.js"),
  Ban: () => import("lucide-vue-next/dist/esm/icons/ban.js"),
  Pencil: () => import("lucide-vue-next/dist/esm/icons/pencil.js"),
  Plus: () => import("lucide-vue-next/dist/esm/icons/plus.js"),
  // eslint-disable-next-line id-length
  X: () => import("lucide-vue-next/dist/esm/icons/x.js"),
  Eye: () => import("lucide-vue-next/dist/esm/icons/eye.js"),
  EyeOff: () => import("lucide-vue-next/dist/esm/icons/eye-off.js"),
  KeyRound: () => import("lucide-vue-next/dist/esm/icons/key-round.js"),
  Mail: () => import("lucide-vue-next/dist/esm/icons/mail.js"),
  Download: () => import("lucide-vue-next/dist/esm/icons/download.js"),
  Wrench: () => import("lucide-vue-next/dist/esm/icons/wrench.js"),
  Settings: () => import("lucide-vue-next/dist/esm/icons/settings.js"),
  History: () => import("lucide-vue-next/dist/esm/icons/history.js"),
  MapPinHouse: () => import("lucide-vue-next/dist/esm/icons/map-pin-house.js"),
  MapPinned: () => import("lucide-vue-next/dist/esm/icons/map-pinned.js"),
  Copy: () => import("lucide-vue-next/dist/esm/icons/copy.js"),
  Calendar: () => import("lucide-vue-next/dist/esm/icons/calendar.js"),
  Image: () => import("lucide-vue-next/dist/esm/icons/image.js"),
  User: () => import("lucide-vue-next/dist/esm/icons/user.js"),
  LogOut: () => import("lucide-vue-next/dist/esm/icons/log-out.js"),
  UnfoldVertical: () => import("lucide-vue-next/dist/esm/icons/unfold-vertical.js"),
  FoldVertical: () => import("lucide-vue-next/dist/esm/icons/fold-vertical.js"),
  UnfoldHorizontal: () => import("lucide-vue-next/dist/esm/icons/unfold-horizontal.js"),
  FoldHorizontal: () => import("lucide-vue-next/dist/esm/icons/fold-horizontal.js"),
  Columns3: () => import("lucide-vue-next/dist/esm/icons/columns-3.js"),
  Upload: () => import("lucide-vue-next/dist/esm/icons/upload.js"),
  File: () => import("lucide-vue-next/dist/esm/icons/file.js"),
  Globe: () => import("lucide-vue-next/dist/esm/icons/globe.js"),
  ListFilter: () => import("lucide-vue-next/dist/esm/icons/list-filter.js"),
  CircleHelp: () => import("lucide-vue-next/dist/esm/icons/circle-help.js"),
  Bold: () => import("lucide-vue-next/dist/esm/icons/bold.js"),
  Italic: () => import("lucide-vue-next/dist/esm/icons/italic.js"),
  Underline: () => import("lucide-vue-next/dist/esm/icons/underline.js"),
  Strikethrough: () => import("lucide-vue-next/dist/esm/icons/strikethrough.js"),
  Type: () => import("lucide-vue-next/dist/esm/icons/type.js"),
  Baseline: () => import("lucide-vue-next/dist/esm/icons/baseline.js"),
  List: () => import("lucide-vue-next/dist/esm/icons/list.js"),
  ListOrdered: () => import("lucide-vue-next/dist/esm/icons/list-ordered.js"),
  RemoveFormatting: () => import("lucide-vue-next/dist/esm/icons/remove-formatting.js"),
  ClipboardPenLine: () => import("lucide-vue-next/dist/esm/icons/clipboard-pen-line.js"),
} as const satisfies Partial<Record<keyof typeof icons, () => Promise<{ default: Component }>>>;
