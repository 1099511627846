import type { IStorage } from "./types/IStorage";
import { localStorage, sessionStorage } from "./webStorage";

const availableStorage: readonly IStorage[] = [localStorage, sessionStorage] as const;

export const getStorage = (preferredStorage: "local" | "session"): IStorage | undefined => {
  const preferredImpl = preferredStorage === "local" ? localStorage : sessionStorage;

  // Try preferred storage first
  if (preferredImpl.isSupported()) {
    return preferredImpl;
  }

  // Fall back to any supported storage
  return availableStorage.find((store) => store.isSupported());
};
