<script setup lang="ts">
import { t } from "@/utils/i18n";
import BaseIcon, { type IconProps } from "@/components/base/BaseIcon/BaseIcon.vue";
import type { Severity, Toast } from "./types";

defineProps<{
  toasts: Toast[];
}>();

defineEmits<{
  remove: [id: number];
}>();

const severityClass = (severity: string) => {
  return {
    [`app-toast--${severity}`]: true,
  };
};

const iconMap: Record<Severity, IconProps["icon"]> = {
  success: "Check",
  info: "Info",
  warning: "TriangleAlert",
  error: "TriangleAlert",
  default: "Info",
};

const getToastIcon = (severity: Severity) => {
  return iconMap[severity];
};
</script>

<template>
  <div data-test="toast" class="app-toast">
    <TransitionGroup name="toast" appear>
      <div
        v-for="toast in toasts.slice(0, 3)"
        :key="toast.id"
        data-test="toast-item"
        class="app-toast__item"
        :class="severityClass(toast.severity)"
        role="log"
      >
        <div class="app-toast__item__side">
          <BaseIcon :icon="getToastIcon(toast.severity)" class="app-toast__icon" />

          <div class="app-toast__item__side__container">
            <p v-if="toast.title" class="app-toast__title">
              {{ toast.title }}
            </p>

            <p>{{ toast.message }}</p>
          </div>
        </div>

        <div>
          <button
            type="button"
            class="app-toast__close-btn"
            :aria-label="t('common.actions.close')"
            @click="$emit('remove', toast.id)"
          >
            <BaseIcon icon="X" />
          </button>
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<!-- eslint-disable vue/max-lines-per-block -->
<style scoped lang="scss">
.app-toast {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-2;
  margin-bottom: $spacing-8;
  z-index: $z-30;

  &__item {
    display: flex;
    justify-content: space-between;
    width: 25rem;
    border-radius: $rounded-base;
    line-height: $leading-snug;
    box-shadow: $shadow-lg;

    &__side {
      display: flex;
      padding: $spacing-4;
      overflow-x: hidden;

      &__container {
        max-width: 100%;
        word-break: break-word;
      }
    }
  }

  &__icon {
    margin-top: $spacing-1;
    margin-right: $spacing-4;
  }

  &__title {
    font-weight: $font-medium;
    margin-bottom: $spacing-1;
  }

  &__close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $spacing-4;
    color: inherit;
    transition: opacity $duration-100;

    &:hover {
      opacity: 0.6;
    }
  }

  &--info {
    color: $info-5;
    background: $info-1;
    border: 1px solid $info-3;
  }

  &--success {
    color: $success-5;
    background: $success-1;
    border: 1px solid $success-3;
  }

  &--warning {
    color: $warning-5;
    background: $warning-1;
    border: 1px solid $warning-3;
  }

  &--error {
    color: $error-5;
    background: $error-1;
    border: 1px solid $error-3;
  }
}

.toast-enter-to {
  animation: bounceInUp $duration-700;
}

.toast-enter-from,
.toast-leave-to {
  opacity: 0;
  transition: opacity $duration-200;
}

.toast-leave-active {
  z-index: $z-negative-1;
  position: absolute;
}

.toast-move {
  transition:
    transform $duration-200,
    opacity $duration-200;
}

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    transform: translate3d(0, 3000px, 0); /* Start below the screen */
  }
  60% {
    transform: translate3d(0, -25px, 0); /* Move up past the final position */
  }
  75% {
    transform: translate3d(0, 10px, 0); /* Move down a bit */
  }
  90% {
    transform: translate3d(0, -5px, 0); /* Move up to nearly final position */
  }
  to {
    transform: none; /* Settle at final position */
  }
}
</style>
