import type { InternalAxiosRequestConfig } from "axios";
import type { GetAntiForgeryTokenResponse } from "@/types/_generated/api";
import { http } from "@/utils/http";

const ANTI_FORGERY_URL = "anti-forgery";

let requestToken = "";
let headerName = "";
let isFetching = false;

const getAntiForgeryToken = async () => {
  try {
    const response = await http.get<GetAntiForgeryTokenResponse>(ANTI_FORGERY_URL);
    ({ requestToken, headerName } = response.data);
  } catch {
    throw new Error("Failed to get anti-forgery token");
  }
};

const setAntiForgeryHeader = (config: InternalAxiosRequestConfig) => {
  if (!headerName || !requestToken) {
    throw new Error("Anti-forgery token not set");
  }

  config.headers[headerName] = requestToken;
};

export const antiForgeryInterceptor = async (config: InternalAxiosRequestConfig) => {
  if (!requestToken && !isFetching) {
    isFetching = true;
    await getAntiForgeryToken();
    isFetching = false;
  }

  const isSusceptibleToXsrf = ["post", "put", "delete", "patch"].includes(
    config.method?.toLowerCase() || "",
  );

  if (isSusceptibleToXsrf) {
    setAntiForgeryHeader(config);
  }

  return config;
};
