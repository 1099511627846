<!-- 
  Available icons:
  - Lucide icons: https://lucide.dev/icons/ (add new icons to the lucideIconsMap object)
  - Custom icons: see /src/assets/icons/ (add new icons to the CustomIconName type)
-->
<script setup lang="ts">
import { computed } from "vue";
import type { IconProps, LucideIconName, CustomIconName } from "./types";
import { lucideIconsMap, lucideSizeMap, createAsyncIconLoader } from "./helpers";

export type { IconProps };

const props = defineProps<IconProps>();

const isLucideIcon = computed(() => /^[A-Z]/.test(props.icon));

const classes = computed(() => {
  if (isLucideIcon.value) return ["base-icon"];
  return ["base-icon", `base-icon--${props.size ?? "small"}`];
});

const lucideSize = computed(() =>
  isLucideIcon.value ? lucideSizeMap[props.size ?? "medium"] : undefined,
);

// Imports either a Lucide icon or a custom SVG component
const iconComponent = computed(() => {
  if (isLucideIcon.value) {
    const iconLoader = lucideIconsMap[props.icon as LucideIconName];
    if (!iconLoader) {
      // eslint-disable-next-line no-console
      console.error(
        `Icon "${props.icon}" not found in lucideIconsMap. If you want to use this Lucide icon, add it to lucideIconsMap object`,
      );
      return;
    }
    return createAsyncIconLoader(iconLoader, props.icon, "Lucide");
  }

  return createAsyncIconLoader(
    () =>
      import(
        `@/assets/icons/Icon${(props.icon as CustomIconName)
          .split("-")
          .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
          .join("")}.vue`
      ),
    props.icon,
    "Custom",
  );
});
</script>

<template>
  <component
    :is="iconComponent"
    :class="classes"
    v-bind="isLucideIcon ? { size: lucideSize } : {}"
  />
</template>

<style scoped lang="scss">
.base-icon {
  flex-shrink: 0;

  &--small {
    width: 0.75rem;
    height: 0.75rem;
  }

  &--medium {
    width: 1rem;
    height: 1rem;
  }

  &--large {
    width: 3rem;
    height: 3rem;
  }
}
</style>
