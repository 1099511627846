import axios, { type AxiosError } from "axios";
import router from "@/router";
import { AppRouteName } from "@/router/RouteName";
import { useAuthStore } from "@/stores/auth.store";
import { useNotificationsStore } from "@/stores/notifications.store";
import { t } from "@/utils/i18n";

export const getResponseStatusMessage = (error: unknown): string | null =>
  (error as AxiosError<{ responseStatus: { message: string } }>).response?.data?.responseStatus
    ?.message || null;

export const getResponseStatusErrors = (error: unknown): string[] =>
  (
    (error as AxiosError<{ responseStatus: { errors: { message: string }[] } }>).response?.data
      ?.responseStatus?.errors || []
  ).map((error) => error.message);

const getResponseStatusMessageWithFallback = (error: unknown): string =>
  getResponseStatusMessage(error) ?? t("errors.unexpectedError");

export const handleError = (
  error: unknown | AxiosError,
  on?: { action: string; entity: string },
): void => {
  const isAxiosError = axios.isAxiosError(error);

  if (isAxiosError && error.response?.status && axiosErrorHandlers[error.response?.status]) {
    axiosErrorHandlers[error.response?.status]();
  } else if (isAxiosError && !!on && error.response?.status === 400) {
    handleWarningOnAction(error, on);
  } else if (isAxiosError && on) {
    handleErrorOnAction(error, on);
  } else {
    handleUnexpectedError(error);
  }
};

const axiosErrorHandlers: Record<number, () => void> = {
  401: () => {
    if (router.currentRoute.value.name !== AppRouteName.Auth) {
      router.push({ name: AppRouteName.Auth });
      useAuthStore().resetGlobalState();
    }
  },
};

const handleErrorOnAction = (
  error: unknown | AxiosError,
  on: { action: string; entity: string },
) => {
  const { notify } = useNotificationsStore();
  notify.failure(on.entity, on.action, getResponseStatusMessageWithFallback(error));
};

const handleWarningOnAction = (
  error: unknown | AxiosError,
  on: { action: string; entity: string },
) => {
  const { notify } = useNotificationsStore();
  notify.warning(on.entity, on.action, getResponseStatusMessageWithFallback(error));
};

const handleUnexpectedError = (error?: unknown) => {
  const { addToast } = useNotificationsStore();
  const authStore = useAuthStore();

  addToast("error", t("errors.unexpectedErrorTitle"), t("errors.unexpectedError"));

  // eslint-disable-next-line no-console
  console.error(error);

  if (!authStore.isAuthenticated) {
    axiosErrorHandlers[401]();
  }
};
