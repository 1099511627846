/* eslint-disable @typescript-eslint/no-explicit-any */
// https://github.com/intlify/vue-i18n-next/tree/master/examples/lazy-loading/webpack
// eslint-disable-next-line no-restricted-imports
import { createI18n } from "vue-i18n";
import en from "./locales/en.json" assert { type: "json" };
import sv from "./locales/sv.json" assert { type: "json" };

interface Options {
  locale: string;
  fallbackLocale?: string;
  messages?: any;
  globalInjection?: boolean;
  legacy?: boolean;
}

type TranslationSchema = typeof en;

const setupI18n = (options: Options = { locale: "en" }) => {
  const i18n = createI18n<[TranslationSchema], "en" | "sv">(options);
  setI18nLanguage(i18n, options.locale);
  return i18n;
};

export const setI18nLanguage = (i18n: any, locale: string) => {
  if (i18n.mode === "legacy") {
    i18n.global.locale = locale;
  } else {
    i18n.global.locale.value = locale;
  }

  if (typeof document !== "undefined") {
    document.querySelector("html")?.setAttribute("lang", locale);
  }
};

export const i18n = setupI18n({
  globalInjection: true,
  legacy: false,
  locale: "en",
  fallbackLocale: "en",
  messages: { en, sv },
});
