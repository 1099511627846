import type { GetApplicationSettingsResponse } from "@/types/_generated/api";
import { http } from "@/utils/http";
import { t } from "@/utils/i18n";
import { handleError } from "./shared/handleError";

export const getApplicationSettingsService = async (): Promise<GetApplicationSettingsResponse> => {
  try {
    const { data } = await http.get<GetApplicationSettingsResponse>(`/application-settings`);
    return data;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("common.settings"),
    });
    throw error;
  }
};
