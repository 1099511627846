const getRuntimeMode = () => {
  // Check if we're in a test environment
  if (typeof import.meta === "undefined" || !import.meta.env) {
    return "test";
  }

  // Check Vite's env
  if (import.meta.env.MODE) {
    return import.meta.env.MODE;
  }

  return "production";
};

export const isDevelopment = getRuntimeMode() === "development";

export const isProduction = getRuntimeMode() === "production";

export const isTest = getRuntimeMode() === "test";
