import { computed, onMounted, ref } from "vue";
import { useAuthentication } from "@/composables/useAuthentication";
import { ViewAuthStatus } from "@/router/ViewAuthStatus";

const TIMEOUT_SECONDS = 1800; // 30 minutes
const WARNING_THRESHOLD = TIMEOUT_SECONDS / 10; // 3 minutes
const INTERVAL_DELAY = 1000; // 1 second in milliseconds

const timer = ref(TIMEOUT_SECONDS);
const showSessionModal = ref(false);

export const updateSessionActivity = (): void => {
  timer.value = TIMEOUT_SECONDS;
  showSessionModal.value = false;
};

export const useSessionTimeout = () => {
  const auth = useAuthentication();

  const intervalId = ref<number>();

  const formattedTime = computed(() => {
    const minutes = Math.floor(timer.value / 60);
    const seconds = timer.value % 60;
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  });

  const handleSignOut = async (): Promise<void> => {
    showSessionModal.value = false;

    await auth.signOutAndCleanup(ViewAuthStatus.SessionExpired);
  };

  const closeModal = async (): Promise<void> => {
    updateSessionActivity();

    // Ping auth endpoint to keep both session timeouts in sync
    try {
      await fetch("/auth", {
        method: "POST",
        credentials: "include",
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Failed to update session activity:", error);
    }
  };

  const startInterval = (): void => {
    intervalId.value = window.setInterval(() => {
      if (!auth.isFullyAuthenticated.value) return;

      timer.value--;

      // Show warning modal when 3 minutes remaining
      if (timer.value <= WARNING_THRESHOLD) {
        showSessionModal.value = true;
      }

      // Sign out user when timer reaches 0
      if (timer.value <= 0) {
        void handleSignOut();
      }
    }, INTERVAL_DELAY);
  };

  onMounted(() => startInterval());

  return {
    showSessionModal,
    formattedTime,
    handleSignOut,
    closeModal,
  };
};
