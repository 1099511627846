import { DefaultBaseResponse } from "@/constants/DefaultBaseResponse";
import { isDevelopment } from "@/helpers/environment";
import { handleError } from "@/services/shared/handleError";
import {
  type GetSsoSettingsResponse,
  type IsAuthenticatedResponse,
  type MfaMethod,
} from "@/types/_generated/api";
import { http } from "@/utils/http";

export const signInWithCredentialsService = async (
  username: string,
  password: string,
  meta?: { mfaCode?: string; mfaMethod?: MfaMethod },
) => {
  try {
    await http.request({
      method: "POST",
      url: "/auth/credentials",
      data: { username, password, meta },
      baseURL: "",
    });
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const signInWithSSOService = async (username: string, key: string): Promise<boolean> => {
  try {
    await http.request({
      method: "POST",
      url: "/auth/sso",
      data: { username, password: key },
      baseURL: "",
    });
    return true;
  } catch (error) {
    handleError(error);
    return false;
  }
};

export const getSSOSettingsService = async (): Promise<GetSsoSettingsResponse> => {
  try {
    const { data } = await http.get<GetSsoSettingsResponse>("/login/sso-settings");
    return data;
  } catch (error) {
    if (isDevelopment) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
  return Object.assign(new DefaultBaseResponse(), { showSsoLogin: false });
};

export const signOutService = async () => {
  try {
    await http.request({
      method: "POST",
      url: "/auth/logout",
      baseURL: "",
    });
  } catch (error) {
    handleError(error);
  }
};

export const checkSessionService = async (): Promise<IsAuthenticatedResponse> => {
  try {
    const { data } = await http.get<IsAuthenticatedResponse>("/login/is-authenticated");
    return data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const acceptTosService = async () => {
  try {
    await http.put("/login/accept-terms");
  } catch (error) {
    handleError(error);
    throw error;
  }
};
