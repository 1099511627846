import { defineStore } from "pinia";
import { ref } from "vue";
import type { Toast, Severity } from "@/components/app/Toast/types";
import { t } from "@/utils/i18n";

const closeAfterMs: { [K in Severity]: number } = {
  default: -1,
  success: 4_000,
  info: 20_000,
  warning: 30_000,
  error: 120_000,
} as const;

export const useNotificationsStore = defineStore("notifications", () => {
  const toasts = ref<Toast[]>([]);

  const addToast = (severity: Severity, title: string, message: string) => {
    const id = Math.floor(Math.random() * 10000);

    const closeTimeInMs = closeAfterMs[severity] ?? -1;
    if (closeTimeInMs > 0) {
      setTimeout(() => removeToast(id), closeTimeInMs);
    }

    toasts.value.unshift({
      severity,
      title,
      message,
      id,
    });
  };

  const removeToast = (id: number) => {
    const removeIndex = toasts.value.findIndex((item) => item.id === id);
    if (removeIndex !== -1) {
      toasts.value.splice(removeIndex, 1);
    }
  };

  const notify = {
    failure: (entity: string, action: string, message: string) =>
      createErrorNotification(entity, action, message),
    success: (entity: string, action: string) => {
      addToast(
        "success",
        t("notifications.success.title", { entity, action }),
        t("notifications.success.description", { entity, action }),
      );
    },
    warning: (entity: string, action: string, message: string) => {
      addToast(
        "warning",
        t("notifications.warning.title"),
        t("notifications.warning.description", { entity, action, message }),
      );
    },
  };

  const createErrorNotification = (entity: string, action: string, message: string) => {
    addToast(
      "error",
      t("notifications.failure.title", { entity, action, message }),
      `${t("notifications.failure.description", {
        entity,
        action,
        message,
      }).replace(/\.$/, "")}. ${t("notifications.failure.ifProblemPersists")}`,
    );
  };

  const $reset = () => {
    toasts.value = [];
  };

  return {
    toasts,
    notify,
    addToast,
    removeToast,
    $reset,
  };
});
