import type { IStorage } from "./types/IStorage";
import type { StorageSchema } from "./types/StorageSchema";

enum WebStorageType {
  localStorage = "localStorage",
  sessionStorage = "sessionStorage",
}

class WebStorage implements IStorage {
  private storage: Storage;

  constructor(type: WebStorageType) {
    this.storage = window[type];
  }

  set<T extends keyof StorageSchema>(key: T, value: StorageSchema[T]): void {
    const stringified = JSON.stringify(value);
    this.storage.setItem(key, stringified);
  }

  get<T extends keyof StorageSchema>(key: T): StorageSchema[T] | null {
    const item = this.storage.getItem(key);
    if (item === null) return null;

    try {
      return JSON.parse(item) as StorageSchema[T];
    } catch {
      return null;
    }
  }

  remove<T extends keyof StorageSchema>(key: T): void {
    this.storage.removeItem(key);
  }

  clear(): void {
    this.storage.clear();
  }

  isSupported(): boolean {
    const testKey = "__test__";

    try {
      this.storage.setItem(testKey, "1");
      this.storage.removeItem(testKey);
      return true;
    } catch {
      return false;
    }
  }
}

export const localStorage = new WebStorage(WebStorageType.localStorage);

export const sessionStorage = new WebStorage(WebStorageType.sessionStorage);
