import { Role } from "@/types/_generated/api";

const ROLE_HIERARCHY = {
  [Role.SystemOwner]: 0,
  [Role.SA]: 1,
  [Role.BA]: 2,
  [Role.INT]: 3,
  [Role.EXT]: 4,
} as const;

export const isSuperior = (role: Role, compareTo: Role): boolean => {
  if (!(role in ROLE_HIERARCHY) || !(compareTo in ROLE_HIERARCHY)) {
    throw new Error(`Invalid role comparison: ${role} vs ${compareTo}`);
  }

  return ROLE_HIERARCHY[role] < ROLE_HIERARCHY[compareTo];
};
