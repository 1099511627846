import { defineStore } from "pinia";
import { ref } from "vue";
import { checkSessionService } from "@/services/auth.service";
import { useAppStore } from "./app.store";
import { useNotificationsStore } from "./notifications.store";

export const useAuthStore = defineStore("auth", () => {
  const appStore = useAppStore();
  const notificationsStore = useNotificationsStore();

  const isAuthenticated = ref(false);
  const isImpersonated = ref(false);

  const checkSession = async () => {
    const sessionData = await checkSessionService();
    if (sessionData.isAuthenticated && sessionData.user) {
      await appStore.updateUser(sessionData.user);
      isAuthenticated.value = true;
      isImpersonated.value = sessionData.isImpersonated;
    } else {
      resetGlobalState();
    }
    return sessionData.isAuthenticated;
  };

  const resetGlobalState = () => {
    $reset();
    appStore.$reset();
    notificationsStore.$reset();
  };

  const $reset = () => {
    isAuthenticated.value = false;
    isImpersonated.value = false;
  };

  return {
    isAuthenticated,
    isImpersonated,
    checkSession,
    resetGlobalState,
  };
});
