import {
  type RouteRecordRaw,
  createRouter,
  createWebHistory,
  createMemoryHistory,
} from "vue-router";
import { useAuthorization } from "@/composables/useAuthorization";
import { AppRouteName } from "@/router/RouteName";
import { handleError } from "@/services/shared/handleError";
import { useAppStore } from "@/stores/app.store";
import { useAuthStore } from "@/stores/auth.store";
import { Role } from "@/types/_generated/api";
import { adminWebRoutes } from "./modules/adminWebRoutes";
import { appRoutes } from "./modules/appRoutes";
import { recRespRoutes } from "./modules/recRespRoutes";
import { userWebRoutes } from "./modules/userWebRoutes";

const routes: Array<RouteRecordRaw> = [
  ...appRoutes,
  ...adminWebRoutes,
  ...userWebRoutes,
  ...recRespRoutes,
];

const createAppHistory = () => {
  // Check if we're in a browser environment (Playwright)
  if (typeof window === "undefined") {
    return createMemoryHistory();
  }
  return createWebHistory();
};

const router = createRouter({
  history: createAppHistory(),
  routes,
  scrollBehavior(_to, _from, _savedPosition) {
    // Manually set scrollTop for layouts since the router only handles window scroll
    const userWebLayout = document.getElementById("user-web-view-layout");
    if (userWebLayout) userWebLayout.scrollTop = 0;
  },
});

router.beforeEach(async (to, _from, next): Promise<void> => {
  const authStore = useAuthStore();
  const appStore = useAppStore();
  const { isExtWithAdminAccess } = useAuthorization();

  // Public pages are allowed
  if (to.meta.isPublic === true) {
    next();
    return;
  }

  // Update session if necessary
  if (!authStore.isAuthenticated) {
    try {
      await authStore.checkSession();
    } catch (error) {
      handleError(error);
    }
  }

  // Go to auth if user is not authenticated
  if (!authStore.isAuthenticated) {
    next({ name: AppRouteName.Auth });
    return;
  }

  // Go to auth if user is not loaded
  if (!appStore.user) {
    next({ name: AppRouteName.Auth });
    return;
  }

  // Check if user has accepted terms of service
  if (!appStore.user?.hasAcceptedTermsOfService) {
    next({ name: AppRouteName.TermsOfService });
    return;
  }

  // Check if user is allowed to access user web
  if (
    appStore.user?.role === Role.EXT &&
    !appStore.user?.allowUserWeb &&
    to.path.startsWith("/user-web/")
  ) {
    next({ path: "/404" });
    return;
  }

  const moduleId = Number(to.params.moduleId);
  const isExt = appStore.user?.role === Role.EXT;
  const isAdminRoute = to.path.startsWith("/admin/");

  const isExtWithoutAdminAccess = isExt && isAdminRoute && !isExtWithAdminAccess(moduleId);

  if (isExtWithoutAdminAccess) {
    next({ path: "/404" });
  } else {
    next();
  }
});

export default router;
