import { computed } from "vue";
import { useRouter } from "vue-router";
import { UserWebRouteName, AdminRouteName, AppRouteName } from "@/router/RouteName";
import { ViewAuthStatus } from "@/router/ViewAuthStatus";
import { signOutService } from "@/services/auth.service";
import { type BaseActionMessage, BroadcastService } from "@/services/shared/BroadcastService";
import { useAppStore } from "@/stores/app.store";
import { useAuthStore } from "@/stores/auth.store";
import { Role } from "@/types/_generated/api";
import { getStorage } from "@/utils/storage/storage";

type AuthMessage = BaseActionMessage & {
  action: "login" | "logout";
};

export const remoteAuthBroadcastService = new BroadcastService<AuthMessage>("auth", "remote");

export const useAuthentication = () => {
  const router = useRouter();
  const appStore = useAppStore();
  const authStore = useAuthStore();

  const isFullyAuthenticated = computed(
    (): boolean => authStore.isAuthenticated && Boolean(appStore.user?.hasAcceptedTermsOfService),
  );

  const authenticateAndRedirect = async () => {
    await authStore.checkSession();
    remoteAuthBroadcastService.post({ action: "login" });

    // Handle redirect after successful login
    redirectOnLogin();
  };

  const redirectOnLogin = () => {
    const goRoute = router.currentRoute.value.query?.go;

    if (goRoute) {
      router.push(decodeURIComponent(goRoute as string));
    } else if (appStore.user?.role === Role.EXT && appStore.user?.allowUserWeb) {
      router.push({ name: UserWebRouteName.Modules });
    } else {
      router.push({ name: AdminRouteName.AdminDashboard });
    }
  };

  const signOutAndCleanup = async (reason: ViewAuthStatus = ViewAuthStatus.SignedOut) => {
    // Try to sign out from the service
    await signOutService();

    // Clear the session storage
    const sessionStorage = getStorage("session");
    sessionStorage?.clear();

    // Reset the auth store
    authStore.resetGlobalState();
    remoteAuthBroadcastService.post({ action: "logout" });

    // Redirect to the auth page
    router.push({
      name: AppRouteName.Auth,
      query: { reason },
    });
  };

  return {
    isFullyAuthenticated,
    authenticateAndRedirect,
    redirectOnLogin,
    signOutAndCleanup,
  };
};
