<script setup lang="ts">
import { useAppStore } from "@/stores/app.store";
import { t } from "@/utils/i18n";
import { useImpersonation } from "@/views/Admin/Users/composables/useImpersonation";
import BaseButton from "@/components/base/Button/BaseButton.vue";

const appStore = useAppStore();
const { endImpersonation, isLoadingImpersonation } = useImpersonation();
</script>

<template>
  <aside class="app-impersonate-warning" role="alert" aria-live="polite">
    <BaseButton
      size="small"
      color="light"
      variant="text"
      :disabled="isLoadingImpersonation"
      @click="endImpersonation"
    >
      {{ t("app.impersonation.endImpersonation", { fullName: appStore.user?.fullName }) }}
    </BaseButton>
  </aside>
</template>

<style scoped lang="scss">
.app-impersonate-warning {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $impersonation-height;
  background-color: $error-4;
}
</style>
