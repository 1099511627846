import type { RouteRecordRaw } from "vue-router";
import { AppRouteName } from "@/router/RouteName";

export const appRoutes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: AppRouteName.Auth,
    component: () => import("@/views/Auth/ViewAuth.vue"),
    meta: { isPublic: true },
  },
  {
    path: "/sso",
    name: AppRouteName.SsoResponse,
    component: () => import("@/views/Auth/ViewSsoResponse.vue"),
    props: ({ query: { username, token } }) => ({
      username,
      token,
    }),
    meta: { isPublic: true },
  },
  {
    path: "/terms-of-service",
    name: AppRouteName.TermsOfService,
    component: () => import("@/views/Auth/ViewTermsOfService.vue"),
    meta: { isPublic: true },
  },
  {
    path: "/reset-password",
    name: AppRouteName.ResetPassword,
    component: () => import("@/views/Auth/ViewResetPassword.vue"),
    props: (route) => ({ token: route.query.token, email: route.query.email }),
    meta: { isPublic: true },
  },
  {
    name: AppRouteName.Licenses,
    path: "/licenses",
    component: () => import("@/views/ViewLicenses.vue"),
  },
  {
    path: "/:notFound(.*)",
    component: () => import("@/views/ViewNotFound.vue"),
  },
];
