import { createPinia } from "pinia";
import { createApp } from "vue";
import { isDevelopment } from "@/helpers/environment";
import App from "./App.vue";
import router from "./router";
import { handleError } from "./services/shared/handleError";
import "@/assets/styles/app.scss";

const pinia = createPinia();
const app = createApp(App);

app.config.errorHandler = function (error) {
  handleError(error);
  // eslint-disable-next-line no-console
  if (isDevelopment) console.error(error);
};

app.use(pinia);
app.use(router);
app.mount("#app");
