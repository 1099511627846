import type { EndImpersonationResponse, ImpersonateUserResponse } from "@/types/_generated/api";
import { http } from "@/utils/http";
import { t } from "@/utils/i18n";
import { handleError } from "./shared/handleError";

export const impersonateService = async (userId: number): Promise<ImpersonateUserResponse> => {
  try {
    const { data } = await http.post<ImpersonateUserResponse>(`/admin/impersonate/${userId}`, {
      params: { userId },
    });
    return data;
  } catch (error) {
    handleError(error, {
      action: t("app.impersonation.impersonate").toLowerCase(),
      entity: t("users.user"),
    });
    throw error;
  }
};

export const endImpersonationService = async (): Promise<EndImpersonationResponse> => {
  try {
    const { data } = await http.post<EndImpersonationResponse>(`/admin/impersonate/logout`);
    return data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};
